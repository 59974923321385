/* eslint-disable no-console */
import Frame from './Frame';
import AppContext from '../AppContext';
import NotificationStyle from './Notification.styles';
import {ReactComponent as CloseIcon} from '../images/icons/close.svg';
import {ReactComponent as CheckmarkIcon} from '../images/icons/checkmark-fill.svg';
import {ReactComponent as WarningIcon} from '../images/icons/warning-fill.svg';
import NotificationParser, {clearURLParams} from '../utils/notifications';
import {getPortalLink} from '../utils/helpers';

const React = require('react');

const Styles = () => {
    return {
        frame: {
            zIndex: '4000000',
            position: 'fixed',
            top: '0',
            right: '0',
            maxWidth: '415px',
            width: '100%',
            // MUSICPEAKS - our messages use more than default height (120px)
            height: '500px',
            animation: '250ms ease 0s 1 normal none running animation-bhegco',
            transition: 'opacity 0.3s ease 0s',
            overflow: 'hidden'
        }
    };
};

const MPMessages = {
    walletState: 'mp.user.walletState',
    tokenClaimSuccess: 'mp.user.tokenClaimSuccess'
};

const NotificationState = {
    error: 'error',
    success: 'success',
    warning: 'warning',
    complete: 'complete',
    working: 'working',
    unknown: 'unknown'
};

const NotificationText = ({type, status, context}) => {
    const signinPortalLink = getPortalLink({page: 'signin', siteUrl: context.site.url});
    const singupPortalLink = getPortalLink({page: 'signup', siteUrl: context.site.url});

    // MUSICPEAKS
    // eslint-disable-next-line no-console
    console.log('preparing text for notifiation', JSON.stringify({type, status}));
    if (type === 'signin' && status === 'success' && context.member) {
        const firstname = context.member.firstname || '';
        return (
            <p>
                Welcome {(firstname ? ', ' + firstname : '')}!<br />You've successfully signed in.
            </p>
        );
    // MUSICPEAKS
    } else if (type === 'signin:web3mem' && status === 'success') {
        const firstname = context.member.firstname || '';
        return (
            <p>
                Welcome back{(firstname ? ', ' + firstname : '')}!<br />You've successfully signed in.
            </p>
        );
    } else if (type === 'signin' && status === 'error') {
        return (
            <p>
                Could not sign in. Login link expired. <a href={signinPortalLink} target="_parent">Click here to retry</a>
            </p>
        );
    } else if (type === 'signup' && status === 'success') {
        return (
            <p>
                You've successfully subscribed to <br /><strong>{context.site.title}</strong>
            </p>
        );
    } else if (type === 'signup-paid' && status === 'success') {
        return (
            <p>
                You've successfully subscribed to <br /><strong>{context.site.title}</strong>
            </p>
        );
    } else if (type === 'updateEmail' && status === 'success') {
        return (
            <p>
                Success! Your email is updated.
            </p>
        );
    } else if (type === 'updateEmail' && status === 'error') {
        return (
            <p>
                Could not update email! Invalid link.
            </p>
        );
    } else if (type === 'signup' && status === 'error') {
        return (
            <p>
                Signup error: Invalid link <br /><a href={singupPortalLink} target="_parent">Click here to retry</a>
            </p>
        );
    } else if (type === 'signup-paid' && status === 'error') {
        return (
            <p>
                Signup error: Invalid link <br /><a href={singupPortalLink} target="_parent">Click here to retry</a>
            </p>
        );
    } else if (type === 'stripe:checkout' && status === 'success') {
        if (context.member) {
            return (
                <p>
                    Success! Your account is fully activated, you now have access to all content.
                </p>
            );
        }
        return (
            <p>
                Success! Check your email for magic link to sign-in.
            </p>
        );
    } else if (type === 'stripe:checkout' && status === 'warning') {
        // Stripe checkout flow was cancelled
        if (context.member) {
            return (
                <p>
                    Plan upgrade was cancelled.
                </p>
            );
        }
        return (
            <p>
                Plan checkout was cancelled.
            </p>
        );
    // MUSICPEAKS - check for event signin
    } else if (type === 'event:signin' && status === 'success') {
        return (
            <p>
                Congratulations, your attendance at this event has been sucessfully registered!<br/> <br/>
                Look for a follow-up email in a few days with details on how to claim your attendance token.
            </p>
        );
    // MUSICPEAKS - check for event signup
    } else if ((type === 'event:signup' && status === 'success') || (type === 'event:subscribe' && status === 'success')) {
        return (
            <p>
                Congratulations, your attendance at this event has been sucessfully registered!<br/> <br/>
                Look for a follow-up email in a few days with details on how to claim your attendance token.
            </p>
        );
    // MUSICPEAKS - check for event signin/subscribe error
    } else if ((type === 'event:signin' && status === 'error') || (type === 'event:subscribe' && status === 'error')) {
        return (
            <p>
                Could not register for event. Login link expired. <a href={signinPortalLink} target="_parent">Click here to retry</a>
            </p>
        );
    // MUSICPEAKS - check for event signup error
    } else if (type === 'event:signup' && status === 'error') {
        return (
            <p>
                Could not register for event. Login link expired. <a href={signinPortalLink} target="_parent">Click here to retry</a>
            </p>
        );
    // MUSICPEAKS -- check wallet creation state
    } else if (type === MPMessages.walletState && status === NotificationState.complete) {
        return (
            <p>
                Your wallet has been successfully created.
            </p>
        );
    // MUSICPEAKS -- check wallet creation state
    } else if (type === MPMessages.walletState && status === NotificationState.working) {
        return (
            <p>
                Your wallet is being created.
            </p>
        );
    // MUSICPEAKS -- check wallet creation state
    } else if (type === MPMessages.walletState && status === NotificationState.unknown) {
        return (
            <p>
              Initializing wallet creation.
            </p>
        );
    // MUSICPEAKS -- check wallet creation state
    } else if (type === MPMessages.tokenClaimSuccess && status === NotificationState.success) {
        return (
            <p>
              Success - you have claimed this token.
            </p>
        );
    }

    return (
        <p>
            {status === 'success' ? 'Success' : 'Error'}
        </p>
    );
};

class NotificationContent extends React.Component {
    static contextType = AppContext;

    constructor() {
        super();
        this.state = {
            className: ''
        };
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    onNotificationClose() {
        this.props.onHideNotification();
    }

    componentDidUpdate() {
        const {showPopup} = this.context;
        if (!this.state.className && showPopup) {
            this.setState({
                className: 'slideout'
            });
        }
    }

    componentDidMount() {
        const {autoHide, duration = 2400} = this.props;
        const {showPopup} = this.context;
        if (showPopup) {
            this.setState({
                className: 'slideout'
            });
        } else if (autoHide) {
            this.timeoutId = setTimeout(() => {
                this.setState({
                    className: 'slideout'
                });
            }, duration);
        }
    }

    onAnimationEnd(e) {
        if (e.animationName === 'notification-slideout' || e.animationName === 'notification-slideout-mobile') {
            this.props.onHideNotification(e);
        }
    }

    render() {
        const {type, status} = this.props;
        const {className = ''} = this.state;
        const statusClass = status ? `  ${status}` : ' neutral';
        const slideClass = className ? ` ${className}` : '';
        return (
            <div className='gh-portal-notification-wrapper'>
                <div className={`gh-portal-notification${statusClass}${slideClass}`} onAnimationEnd={e => this.onAnimationEnd(e)}>
                    {(status === 'error' ? <WarningIcon className='gh-portal-notification-icon error' alt=''/> : <CheckmarkIcon className='gh-portal-notification-icon success' alt=''/>)}
                    <NotificationText type={type} status={status} context={this.context} />
                    <CloseIcon className='gh-portal-notification-closeicon' alt='Close' onClick={e => this.onNotificationClose(e)} />
                </div>
            </div>
        );
    }
}

export default class Notification extends React.Component {
    static contextType = AppContext;

    constructor() {
        super();
        const {type, status, autoHide, duration} = NotificationParser() || {};
        this.state = {
            active: true,
            type,
            status,
            autoHide,
            duration,
            className: ''
        };
    }

    componentDidMount() {
        this.messageHandler = (event) => {
            this.handleMessage(event);
        };
        // capture messages
        window.addEventListener('message', this.messageHandler, false);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler, false);
    }

    handleMessage(event) {
        if (event.data.eventId === MPMessages.walletState) {
            console.debug(`Notification ${event.data.eventId} message`, event.data.data.walletStatus);
            this.setState({
                active: true,
                type: MPMessages.walletState,
                status: event.data.data.walletStatus,
                autoHide: false,
                duration: 10000,
                className: ''
            });
            return;
        } else if (event.data.eventId === MPMessages.tokenClaimSuccess) {
            console.debug(`Notification ${event.data.eventId} message`, event.data.data.claimStatus);
            this.setState({
                active: true,
                type: MPMessages.tokenClaimSuccess,
                status: event.data.data.claimStatus,
                autoHide: true,
                duration: 10000,
                className: ''
            });
            return;
        } else {
            console.debug('Notification un-handled message', event);
            return;
        }
    }

    onHideNotification() {
        const type = this.state.type;
        const deleteParams = [];
        if (['signin', 'signup', 'signin:web3mem'].includes(type)) {
            deleteParams.push('action', 'success');
        } else if (['stripe:checkout'].includes(type)) {
            deleteParams.push('stripe');
        }
        clearURLParams(deleteParams);
        this.context.onAction('refreshMemberData');
        this.setState({
            active: false
        });
    }

    renderFrameStyles() {
        const styles = `
            :root {
                --brandcolor: ${this.context.brandColor}
            }
        ` + NotificationStyle;
        return (
            <style dangerouslySetInnerHTML={{__html: styles}} />
        );
    }

    render() {
        const Style = Styles({brandColor: this.context.brandColor});
        const frameStyle = {
            ...Style.frame
        };
        if (!this.state.active) {
            return null;
        }
        const {type, status, autoHide, duration} = this.state;
        if (type && status) {
            return (
                <Frame style={frameStyle} title="portal-notification" head={this.renderFrameStyles()} className='gh-portal-notification-iframe' >
                    <NotificationContent {...{type, status, autoHide, duration}} onHideNotification={e => this.onHideNotification(e)} />
                </Frame>
            );
        }
        return null;
    }
}
