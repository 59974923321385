/* eslint-disable no-console */
import {useContext, useEffect, useState} from 'react';
// MUSICPEAKS
// Using 4.x branch as 5+ requires React 18+
// https://github.com/reactjs/react-tabs/tree/4.x
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import AppContext from '../../AppContext';
import MemberAvatar from '../common/MemberGravatar';
import ActionButton from '../common/ActionButton';
import CloseButton from '../common/CloseButton';
import Switch from '../common/Switch';
import {allowCompMemberUpgrade,
    getCompExpiry,
    getMemberSubscription,
    getMemberTierName,
    getSiteNewsletters,
    getSupportAddress,
    getUpdatedOfferPrice,
    hasCommentsEnabled,
    hasMultipleNewsletters,
    hasMultipleProductsFeature,
    hasOnlyFreePlan,
    isComplimentaryMember,
    subscriptionHasFreeTrial,
    memberHasWeb3MembershipToken,
    fetchMemberTokens,
    fetchMemberWallet} from '../../utils/helpers';
import {getDateString} from '../../utils/date-time';
import {ReactComponent as LoaderIcon} from '../../images/icons/loader.svg';
import {ReactComponent as OfferTagIcon} from '../../images/icons/offer-tag.svg';

// import 'react-tabs/style/react-tabs.css';

const React = require('react');

const tabBorderColor = '#ddd';

export const AccountHomePageStyles = `
    .gh-portal-account-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 32px;
    }

    .gh-portal-account-header .gh-portal-avatar {
        margin: 6px 0 8px !important;
    }

    .gh-portal-account-data {
        margin-bottom: 40px;
    }

    footer.gh-portal-account-footer {
        display: flex;
    }

    .gh-portal-account-footer.paid {
        margin-top: 12px;
    }

    .gh-portal-account-footermenu {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .gh-portal-account-footerright {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-end;
    }

    .gh-portal-account-footermenu li {
        margin-right: 16px;
    }

    .gh-portal-account-footermenu li:last-of-type {
        margin-right: 0;
    }

    .gh-portal-freeaccount-newsletter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
    }

    .gh-portal-freeaccount-newsletter .label {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .gh-portal-free-ctatext {
        margin-top: -12px;
    }

    .gh-portal-cancelcontinue-container {
        margin: 24px 0 32px;
    }

    .gh-portal-billing-button-loader {
        width: 32px;
        height: 32px;
        margin-right: -3px;
        opacity: 0.6;
    }

    .gh-portal-product-icon {
        width: 52px;
        margin-right: 12px;
        border-radius: 2px;
    }

    .gh-portal-account-discountcontainer {
        position: relative;
        display: flex;
        align-items: center;
    }

    .gh-portal-account-old-price {
        text-decoration: line-through;
        color: var(--grey9) !important;
    }

    .gh-portal-account-tagicon {
        width: 16px;
        height: 16px;
        color: var(--brandcolor);
        margin-right: 5px;
        z-index: 999;
    }

    @media (max-width: 390px) {
        .gh-portal-account-footer {
            padding: 0 !important;
        }
    }

    @media (max-width: 340px) {
        .gh-portal-account-footer {
            padding: 0 !important;
            flex-wrap: wrap;
            gap: 12px;
        }

        .gh-portal-account-footer .gh-portal-account-footerright {
            justify-content: flex-start;
        }
    }

    .react-tabs {
      -webkit-tap-highlight-color: transparent;
    }
    
    .react-tabs__tab-list {
      margin: 0 0 10px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    
    .react-tabs__tab {
      display: inline-block;
      border: 1px solid transparent;
      border-bottom: none;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 6px 24px;
      cursor: pointer;

      background: #f7f7f7;
      border-color: ${tabBorderColor};
      color: black;
      border-radius: 5px 5px 0 0;
    }
    
    .react-tabs__tab--selected {
      background: #fff;
      border-color: ${tabBorderColor};
      color: black;
      border-radius: 5px 5px 0 0;
    }
    
    .react-tabs__tab--disabled {
      color: lightgray;
      cursor: default;
    }
    
    .react-tabs__tab:focus {
      outline: none;
    }
    
    .react-tabs__tab:focus:after {
      content: '';
      position: absolute;
      height: 5px;
      left: -4px;
      right: -4px;
      bottom: -5px;
      background: #fff;
    }
    
    .react-tabs__tab-panel {
      display: none;
    }
    
    .react-tabs__tab-panel--selected {
      display: block;
      margin-top: -10px;
    }

    .mp-token-item {
      display: flex;
      gap: 16px;
    }

    .mp-token-front {
      width: 32px;
      height: auto;
    }

`;

const UserAvatar = ({avatar, brandColor}) => {
    return (
        <>
            <MemberAvatar gravatar={avatar} style={{userIcon: {color: brandColor, width: '56px', height: '56px', padding: '2px'}}} />
        </>
    );
};

const AccountFooter = ({onClose, handleSignout, supportAddress = ''}) => {
    const supportAddressMail = `mailto:${supportAddress}`;
    return (
        <footer className='gh-portal-account-footer'>
            <ul className='gh-portal-account-footermenu'>
                <li><button className='gh-portal-btn' name='logout' aria-label='logout' onClick={e => handleSignout(e)}>Sign out</button></li>
            </ul>
            <div className='gh-portal-account-footerright'>
                <ul className='gh-portal-account-footermenu'>
                    <li><a className='gh-portal-btn gh-portal-btn-branded' href={supportAddressMail} onClick={() => {
                        supportAddressMail && window.open(supportAddressMail);
                    }}>Contact support</a></li>
                </ul>
            </div>
        </footer>
    );
};

// MusicPeaks
const UserHeader = ({tabIndex}) => {
    const {member, brandColor} = useContext(AppContext);
    const avatar = member.avatar_image;
    if (tabIndex === 0) {
        return (
            <header className='gh-portal-account-header'>
                <UserAvatar avatar={avatar} brandColor={brandColor} />
                <h2 className="gh-portal-main-title">Your account</h2>
                <p className="mt6 gh-portal-text-center">You currently have a basic membership. You'll be able to level up your membership by participating in the community, attending virtual events and collecting digital
            goods.</p>
            </header>
        );
    } else if (tabIndex === 1) {
        return (
            <header className='gh-portal-account-header'>
                <UserAvatar avatar={avatar} brandColor={brandColor} />
                <h2 className="gh-portal-main-title">Your tokens</h2>
                <p className="mt6 gh-portal-text-center">Below are a list of tokens that you have claimed, earned or bought on this site.</p>
            </header>
        );
    } else if (tabIndex === 2) {
        return (
            <header className='gh-portal-account-header'>
                <UserAvatar avatar={avatar} brandColor={brandColor} />
                <h2 className="gh-portal-main-title">Other wallets</h2>
                <p className="mt6 gh-portal-text-center">When you joined this site a wallet was set up for you to hold your digital goods. To take full custody of your property, you may connect a parent wallet below. More info here.</p>
            </header>
        );
    } else {
        return <div>unknown tab index {tabIndex}</div>;
    }
};

function getOfferLabel({offer, price, subscriptionStartDate}) {
    let offerLabel = '';

    if (offer) {
        const discountDuration = offer.duration;
        let durationLabel = '';
        if (discountDuration === 'forever') {
            durationLabel = `Forever`;
        } else if (discountDuration === 'repeating') {
            const durationInMonths = offer.duration_in_months || 0;
            let offerStartDate = new Date(subscriptionStartDate);
            let offerEndDate = new Date(offerStartDate.setMonth(offerStartDate.getMonth() + durationInMonths));
            durationLabel = `Ends ${getDateString(offerEndDate)}`;
        }
        offerLabel = `${getUpdatedOfferPrice({offer, price, useFormatted: true})}/${price.interval}${durationLabel ? ` — ${durationLabel}` : ``}`;
    }
    return offerLabel;
}

function FreeTrialLabel({subscription, priceLabel}) {
    if (subscriptionHasFreeTrial({sub: subscription})) {
        const trialEnd = getDateString(subscription.trial_end_at);
        return (
            <p className="gh-portal-account-discountcontainer">
                <div>
                    <span>Free Trial – Ends {trialEnd}</span>
                    {/* <span>{getSubFreeTrialDaysLeft({sub: subscription})} days left</span> */}
                </div>
            </p>
        );
    }
    return null;
}

const PaidAccountActions = () => {
    const {member, site, onAction} = useContext(AppContext);

    const onEditBilling = () => {
        const subscription = getMemberSubscription({member});
        onAction('editBilling', {subscriptionId: subscription.id});
    };

    const openUpdatePlan = () => {
        const {is_stripe_configured: isStripeConfigured} = site;
        if (isStripeConfigured) {
            onAction('switchPage', {
                page: 'accountPlan',
                lastPage: 'accountHome'
            });
        }
    };

    const PlanLabel = ({price, isComplimentary, subscription}) => {
        const {
            offer,
            start_date: startDate
        } = subscription || {};
        let label = '';
        if (price) {
            const {amount = 0, currency, interval} = price;
            label = `${Intl.NumberFormat('en', {currency, style: 'currency'}).format(amount / 100)}/${interval}`;
        }
        let offerLabelStr = getOfferLabel({price, offer, subscriptionStartDate: startDate});
        const compExpiry = getCompExpiry({member});
        if (isComplimentary) {
            if (compExpiry) {
                label = `Complimentary - Expires ${compExpiry}`;
            } else {
                label = label ? `Complimentary (${label})` : `Complimentary`;
            }
        }
        let oldPriceClassName = '';
        if (offerLabelStr) {
            oldPriceClassName = 'gh-portal-account-old-price';
        }
        const OfferLabel = () => {
            if (offerLabelStr) {
                return (
                    <p className="gh-portal-account-discountcontainer">
                        <OfferTagIcon className="gh-portal-account-tagicon" />
                        <span>{offerLabelStr}</span>
                    </p>
                );
            }
            return null;
        };

        const hasFreeTrial = subscriptionHasFreeTrial({sub: subscription});
        if (hasFreeTrial) {
            oldPriceClassName = 'gh-portal-account-old-price';
        }
        if (hasFreeTrial) {
            return (
                <>
                    <p className={oldPriceClassName}>
                        {label}
                    </p>
                    <FreeTrialLabel subscription={subscription} />
                </>
            );
        }

        return (
            <>
                <p className={oldPriceClassName}>
                    {label}
                </p>
                <OfferLabel />
            </>
        );
    };

    const PlanUpdateButton = ({isComplimentary}) => {
        const hideUpgrade = allowCompMemberUpgrade({member}) ? false : isComplimentary;
        if (hideUpgrade || hasOnlyFreePlan({site})) {
            return null;
        }
        return (
            <button className='gh-portal-btn gh-portal-btn-list' onClick={e => openUpdatePlan(e)}>Change</button>
        );
    };

    const CardLabel = ({defaultCardLast4}) => {
        if (defaultCardLast4) {
            const label = `**** **** **** ${defaultCardLast4}`;
            return (
                <p>
                    {label}
                </p>
            );
        }
        return null;
    };

    const BillingSection = ({defaultCardLast4, isComplimentary}) => {
        const {action} = useContext(AppContext);
        const label = action === 'editBilling:running' ? (
            <LoaderIcon className='gh-portal-billing-button-loader' />
        ) : 'Update';
        if (isComplimentary) {
            return null;
        }

        return (
            <section>
                <div className='gh-portal-list-detail'>
                    <h3>Billing info</h3>
                    <CardLabel defaultCardLast4={defaultCardLast4} />
                </div>
                <button className='gh-portal-btn gh-portal-btn-list' onClick={e => onEditBilling(e)}>{label}</button>
            </section>
        );
    };

    const subscription = getMemberSubscription({member});
    const isComplimentary = isComplimentaryMember({member});
    // MUSICPEAKS - bypass all plan information.  just return null for now
    const bypassPlanData = true;
    if (!bypassPlanData && (subscription || isComplimentary)) {
        const {
            price,
            default_payment_card_last4: defaultCardLast4
        } = subscription || {};
        let planLabel = 'Plan';

        // Show name of tiers if there are multiple tiers
        if (hasMultipleProductsFeature({site}) && getMemberTierName({member})) {
            planLabel = getMemberTierName({member});
        }
        // const hasFreeTrial = subscriptionHasFreeTrial({sub: subscription});
        // if (hasFreeTrial) {
        //     planLabel += ' (Free Trial)';
        // }
        return (
            <>
                <section>
                    <div className='gh-portal-list-detail'>
                        <h3>{planLabel}</h3>
                        <PlanLabel price={price} isComplimentary={isComplimentary} subscription={subscription} />
                    </div>
                    <PlanUpdateButton isComplimentary={isComplimentary} />
                </section>
                <BillingSection isComplimentary={isComplimentary} defaultCardLast4={defaultCardLast4} />
            </>
        );
    }
    return null;
};

//MUSICPEAKS
const TokenListItem = ({
    token,
    onClick
}) => {
    console.log(`token list item is`, token);
    const eventImage = token.eventImage ? token.eventImage : token.float.eventImage;
    const tokenImageUrl = `https://see.mycu.be/ipfs/${eventImage}`;
    return (
        <section>
            <div className='gh-portal-list-detail mp-token-item'>
                <img src={tokenImageUrl} alt="token" className="mp-token-front" />
                <div>
                    <h3>{token.float.eventName}</h3>
                    <p>{token.float.eventHost}</p>
                </div>
            </div>
            <button className='gh-portal-btn gh-portal-btn-list' onClick={(e) => {
                e.preventDefault();
                onClick(token);
            }}>View</button>
        </section>

    ); 
};

const TokenListItems = ({memberTokens, member}) => {
    if (!memberTokens) {
        return null;
    }
    const handleTokenClick = (token) => {
        if (token) {
            // change in strategy here.  link directly to ipfs

            // window.open(`${token.viewUrl}`);
            // console.debug('handle token view for', JSON.stringify(token, null, 2));
            // these are the same for now, but we'll see if we need to include event id with membership.
            if (token.viewUrl){
                window.open(`${token.viewUrl}`, 'tokenWindow');
            } else if (token.tokenType === 'attendance' || token.tokenType === 'membership') {
                window.open(`/tokens/${member.id}/${token.tokenType}/${token.float.eventId}`, '_self');
            }
        } else {
            alert('Sorry, there was an error loading the token details.  Please try refreshing the page');
        }
    };
    return (<>
        {memberTokens.tokens.map((token) => {
            return <TokenListItem token={token} key={token.key} onClick={handleTokenClick} />;
        })}
    </>);
};

//MUSICPEAKS 
const TokenList = () => {
    const {member} = useContext(AppContext);
    const [memberTokens, setMemberTokens] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const fetchMemberTokenData = async () => {
            try {
                setIsFetching(true);
                const data = await fetchMemberTokens(member);
                setIsFetching(false);
                return data;
            } catch (e) {
                // eslint-disable-next-line no-debugger
                debugger;
                console.error(e);
            } finally {
                setIsFetching(false);
            }
        };
        if (member) {
            fetchMemberTokenData().then((res) => {
                console.debug('member tokens data is', res);
                console.debug(`token length is ${res.memberTokens.tokens.length}`);
                setMemberTokens(res.memberTokens);
            });
        }
    }, [member, setMemberTokens, setIsFetching]);
    
    if (isFetching) {
        return (<section>
            <div className='gh-portal-list-detail'>
                <h3>&nbsp;</h3>
                <p>Loading, please wait</p>
            </div>
        </section>);
    } else {
        return (<TokenListItems memberTokens={memberTokens} member={member}/>);
    }
};

//MUSICPEAKS
const MemberTokenListActions = () => {
    return (
        <div>
            <div className='gh-portal-list'>
                <TokenList />
            </div>
        </div>
    );
};

//MUSICPEAKS
const AccountActions = () => {
    const {member, onAction} = useContext(AppContext);
    const {name, email} = member;

    const openEditProfile = () => {
        onAction('switchPage', {
            page: 'accountProfile',
            lastPage: 'accountHome'
        });
    };

    return (
        <div>
            <div className='gh-portal-list'>
                <section>
                    <div className='gh-portal-list-detail'>
                        <h3>{(name ? name : 'Account')}</h3>
                        <p>{email}</p>
                    </div>
                    <button className='gh-portal-btn gh-portal-btn-list' onClick={e => openEditProfile(e)}>Edit</button>
                </section>

                <PaidAccountActions />
                <WalletAddressAction />
                <MembershipTokenClaimAction />
                <EmailPreferencesAction />
                <EmailNewsletterAction />
            </div>
            {/* <ProductList openUpdatePlan={openUpdatePlan}></ProductList> */}
        </div>
    );
};

function EmailNewsletterAction() {
    const {member, site, onAction} = useContext(AppContext);
    let {newsletters} = member;

    if (hasMultipleNewsletters({site}) || hasCommentsEnabled({site})) {
        return null;
    }
    const subscribed = !!newsletters?.length;
    let label = subscribed ? 'Subscribed' : 'Unsubscribed';
    const onToggleSubscription = (e, sub) => {
        e.preventDefault();
        const siteNewsletters = getSiteNewsletters({site});
        const subscribedNewsletters = !member?.newsletters?.length ? siteNewsletters : [];
        onAction('updateNewsletterPreference', {newsletters: subscribedNewsletters});
    };

    return (
        <section>
            <div className='gh-portal-list-detail'>
                <h3>Email newsletter</h3>
                <p>{label}</p>
            </div>
            <div>
                <Switch
                    id="default-newsletter-toggle"
                    onToggle={(e) => {
                        onToggleSubscription(e, subscribed);
                    }} checked={subscribed}
                />
            </div>
        </section>
    );
}

function EmailPreferencesAction() {
    const {site, onAction} = useContext(AppContext);
    if (!hasMultipleNewsletters({site}) && !hasCommentsEnabled({site})) {
        return null;
    }
    return (
        <section>
            <div className='gh-portal-list-detail'>
                <h3>Emails</h3>
                <p>Update your preferences</p>
            </div>
            <button className='gh-portal-btn gh-portal-btn-list' onClick={(e) => {
                onAction('switchPage', {
                    page: 'accountEmail',
                    lastPage: 'accountHome'
                });
            }}>Manage</button>
        </section>
    );
}

// MUSICPEAKS
function WalletAddressAction() {
    const {member} = useContext(AppContext);
    const [walletAddress, setWalletAddress] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const fetchMemberWalletData = async () => {
            try {
                setIsFetching(true);
                const data = await fetchMemberWallet(member);
                return data;
            } catch (e) {
                // eslint-disable-next-line no-debugger
                debugger;
                console.error(e);
            } finally {
                setIsFetching(false);
            }
        };
        if (member) {
            fetchMemberWalletData().then((res) => {
                console.debug('member wallet data is', res);
                if (res.success && res.memberWallet && res.memberWallet.address) {
                    setWalletAddress(res.memberWallet.address);
                } else {
                    setWalletAddress(null);
                }
            });
        }
    }, [member, setWalletAddress, setIsFetching]);
    
    const connectedMessage = walletAddress !== null ? 'Connected' : 'Not Connected';
    if (isFetching) {
        <section>
            <div className='gh-portal-list-detail'>
                <h3>Loading</h3>
            </div>
        </section>;
    }
    return (
        <section>
            <div className='gh-portal-list-detail'>
                <h3>Wallet</h3>
                <p>{walletAddress}</p>
            </div>
            <button className='gh-portal-btn gh-portal-btn-list gh-no-hover' onClick={(e) => {
                e.preventDefault();
            }}>{connectedMessage}</button>
        </section>
    );
}

// MUSICPEAKS
function MembershipTokenClaimAction() {
    const {member} = useContext(AppContext);
    const [membershipToken, setMembershipToken] = useState(null);
    const [fetchStatus, setFetchStatus] = useState(false);
    useEffect(() => {
        const fetchMembershipData = async () => {
            try {
                setFetchStatus(true);
                const data = await memberHasWeb3MembershipToken(member);
                return data;
            } catch (e) {
                // eslint-disable-next-line no-debugger
                debugger;
                // eslint-disable-next-line no-console
                console.error(e);
            } finally {
                setFetchStatus(false);
            }
        };
        if (member) {
            fetchMembershipData().then((res) => {
                if (res && res.memberToken) {
                    console.debug('setting member token to', res);
                    setMembershipToken(res);
                } else {
                    setMembershipToken(null);
                }
            });
        }
    }, [member, setMembershipToken, setFetchStatus]);
    const hasClaimed = membershipToken && membershipToken.memberToken && membershipToken.memberToken.id && membershipToken.memberToken.serial;
    const msg = hasClaimed ? 'Claimed' : 'Not Claimed';
    return (
        <section>
            <div className='gh-portal-list-detail'>
                <h3>Membership Token</h3>
                {fetchStatus ? (
                    <p>checking</p>
                ) : (
                    <p>{msg}</p>
                )}
            </div>
            {hasClaimed ? (
                <button className='gh-portal-btn gh-portal-btn-list' onClick={(e) => {
                    e.preventDefault();
                    if (membershipToken && membershipToken.tokenMeta) {
                        if (membershipToken.tokenMeta.ipfsUrl){
                            window.open(`${membershipToken.tokenMeta.ipfsUrl}`, 'tokenWindow');
                        } else {
                            window.open(`/tokens/${member.id}/membership/${membershipToken.memberToken.id}/`, '_self');
                        }
                    } else {
                        alert('There was an error loading the membership token details.  Please try refreshing the page');
                    }
                }}>View</button>
            ) : (null)}
        </section>
    );
}

const SubscribeButton = () => {
    // MP: don't display this button
    return null;

    // MP: Below is the Original code
    // const {action, brandColor, onAction, site} = useContext(AppContext);
    // const {is_stripe_configured: isStripeConfigured} = site;

    // if (!isStripeConfigured || hasOnlyFreePlan({site})) {
    //     return null;
    // }
    // const isRunning = ['checkoutPlan:running'].includes(action);

    // const openPlanPage = () => {
    //     onAction('switchPage', {
    //         page: 'accountPlan',
    //         lastPage: 'accountHome'
    //     });
    // };
    // return (
    //     <ActionButton
    //         isRunning={isRunning}
    //         label="View plans"
    //         onClick={() => openPlanPage()}
    //         brandColor={brandColor}
    //         style={{width: '100%'}}
    //     />
    // );
};

const AccountWelcome = () => {
    const {member, site} = useContext(AppContext);
    const {is_stripe_configured: isStripeConfigured} = site;

    if (!isStripeConfigured || hasOnlyFreePlan({site})) {
        return null;
    }
    const subscription = getMemberSubscription({member});
    const isComplimentary = isComplimentaryMember({member});
    if (isComplimentary && !subscription) {
        return null;
    }
    if (subscription) {
    // MUSICPEAKS - bypass any information about subscriptions
        const bypassSubscriptionData = true;
        if (bypassSubscriptionData) {
            return null;
        }
        const currentPeriodEnd = subscription?.current_period_end;
        if (isComplimentary && getCompExpiry({member})) {
            const expiryDate = getCompExpiry({member});
            const expiryAt = getDateString(expiryDate);
            return (
                <div className='gh-portal-section'>
                    <p className='gh-portal-text-center gh-portal-free-ctatext'>Your subscription will expire on {expiryAt}</p>
                </div>
            );
        }
        if (subscription?.cancel_at_period_end) {
            return null;
        }

        if (subscriptionHasFreeTrial({sub: subscription})) {
            const trialEnd = getDateString(subscription.trial_end_at);
            return (
                <div className='gh-portal-section'>
                    <p className='gh-portal-text-center gh-portal-free-ctatext'>Your subscription will start on {trialEnd}</p>
                </div>
            );
        }
        return (
            <div className='gh-portal-section'>
                <p className='gh-portal-text-center gh-portal-free-ctatext'>Your subscription will renew on {getDateString(currentPeriodEnd)}</p>
            </div>
        );
    }

    return (
        <div className='gh-portal-section'>
            <p className='gh-portal-text-center gh-portal-free-ctatext'>You currently have a basic membership. You'll be able to level up your membership by participating in the community, attending virtual events and collecting digital goods.</p>
            <SubscribeButton />
        </div>
    );
};

const ContinueSubscriptionButton = () => {
    const {member, onAction, action, brandColor} = useContext(AppContext);
    const subscription = getMemberSubscription({member});
    if (!subscription) {
        return null;
    }

    // To show only continue button and not cancellation
    if (!subscription.cancel_at_period_end) {
        return null;
    }
    const label = subscription.cancel_at_period_end ? 'Continue subscription' : 'Cancel subscription';
    const isRunning = ['cancelSubscription:running'].includes(action);
    const disabled = (isRunning) ? true : false;
    const isPrimary = !!subscription.cancel_at_period_end;

    const CancelNotice = () => {
        if (!subscription.cancel_at_period_end) {
            return null;
        }
        // MUSICPEAKS - bypass any information about subscriptions
        const bypassSubscriptionData = true;
        if (bypassSubscriptionData) {
            return null;
        }
        const currentPeriodEnd = subscription.current_period_end;
        return (
            <p className='gh-portal-text-center gh-portal-free-ctatext'>Your subscription will expire on {getDateString(currentPeriodEnd)}</p>
        );
    };

    return (
        <div className='gh-portal-cancelcontinue-container'>
            <CancelNotice />
            <ActionButton
                onClick={(e) => {
                    onAction('continueSubscription', {
                        subscriptionId: subscription.id
                    });
                }}
                isRunning={isRunning}
                disabled={disabled}
                isPrimary={isPrimary}
                brandColor={brandColor}
                label={label}
                style={{
                    width: '100%'
                }}
            />
        </div>
    );
};

const AccountMain = () => {
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <div className='gh-portal-content gh-portal-account-main'>
            <CloseButton />
            <UserHeader tabIndex={tabIndex} />
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    <Tab>Info</Tab>
                    <Tab>Tokens</Tab>
                    <Tab disabled>Other Wallets</Tab>
                </TabList>
                <TabPanel>
                    <section className='gh-portal-account-data'>
                        <AccountWelcome />
                        <ContinueSubscriptionButton />
                        <AccountActions />
                    </section>
                </TabPanel>
                <TabPanel>
                    <section className='gh-portal-account-data'>
                        <MemberTokenListActions />
                    </section>
                </TabPanel>
                <TabPanel>
                    <h2>Not enabled</h2>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default class AccountHomePage extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
        const {member} = this.context;
        if (!member) {
            this.context.onAction('switchPage', {
                page: 'signin'
            });
        }
    }

    handleSignout(e) {
        e.preventDefault();
        const walletLocalKey = 'MP_USER_WALLET_STATUS';
        window.localStorage.removeItem(walletLocalKey);
        console.log(`deleted from ${walletLocalKey}`);
        this.context.onAction('signout');
    }

    render() {
        const {member, site} = this.context;
        const supportAddress = getSupportAddress({site});
        if (!member) {
            return null;
        }
        return (
            <div className='gh-portal-account-wrapper'>
                <AccountMain />
                <AccountFooter
                    onClose={() => this.context.onAction('closePopup')}
                    handleSignout={e => this.handleSignout(e)}
                    supportAddress={supportAddress}
                />
            </div>
        );
    }
}
